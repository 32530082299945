import { SelectionModel } from '@angular/cdk/collections'
import { JsonPipe } from '@angular/common'
import { Component, input, output } from '@angular/core'
import { MatChipsModule } from '@angular/material/chips'
import { MatIcon } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { TranslocoModule } from '@ngneat/transloco'

import { ButtonComponent } from '~common/ui/button/button.component'
import { TextComponent } from '~common/ui/text/text.component'
import { ActionType, DataTableActionItem } from '~common/utils/data-table'
import { Icon } from '~icons'

@Component({
  selector: 'sb-data-table-toolbar',
  standalone: true,
  imports: [TranslocoModule, MatIcon, MatChipsModule, TextComponent, MatToolbarModule, ButtonComponent, JsonPipe],
  template: `
    <mat-toolbar *transloco="let t">
      @if (selection()) {
        <mat-chip (removed)="selection().clear()" class="mr-4">
          <sb-text variant="labelLarge" noMargin inline class="inline-flex">
            {{ t('common.ui.Selected', { count: selection().selected.length }) }}
          </sb-text>
          @if (selection().selected.length > 0) {
            <button matChipRemove>
              <mat-icon [svgIcon]="Icons.Close" />
            </button>
          }
        </mat-chip>
      }
      @if (selection()?.selected.length > 0) {
        @for (action of actions(); track action) {
          @switch (action.type) {
            @case (ActionType.RECORD) {
              @if (selection().selected.length === 1) {
                <sb-button variant="square" (click)="onAction.emit(action)" [icon]="action.icon">{{
                  t(action.label)
                }}</sb-button>
              }
            }
            @case (ActionType.BULK) {
              <sb-button variant="square" (click)="onAction.emit(action)" [icon]="action.icon">{{
                t(action.label)
              }}</sb-button>
            }
          }
        }
      }
    </mat-toolbar>
  `,
  styles: `
    @use 'sass:map';
    @use 'themes/config/palette';
    @use 'variables' as var;
    mat-toolbar {
      background-color: var.$table-toolbar-bg-light;
      border-radius: var.$table-toolbar-border-radius;
      height: auto;
      min-height: 48px;
      padding: 8px 12px;
      gap: 8px;
      margin-bottom: 4px;

      .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: var.$table-toolbar-chip-bg-light;
      }
    }
  `,
})
export class DataTableToolbarComponent<T> {
  actions = input<DataTableActionItem[]>()
  onAction = output<DataTableActionItem>()
  selection = input<SelectionModel<T>>()
  protected readonly Icons = Icon
  protected readonly ActionType = ActionType
}
